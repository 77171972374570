exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-2025-js": () => import("./../../../src/pages/calendar/2025.js" /* webpackChunkName: "component---src-pages-calendar-2025-js" */),
  "component---src-pages-calendar-archives-2022-js": () => import("./../../../src/pages/calendar/archives/2022.js" /* webpackChunkName: "component---src-pages-calendar-archives-2022-js" */),
  "component---src-pages-calendar-archives-2023-js": () => import("./../../../src/pages/calendar/archives/2023.js" /* webpackChunkName: "component---src-pages-calendar-archives-2023-js" */),
  "component---src-pages-calendar-archives-2024-js": () => import("./../../../src/pages/calendar/archives/2024.js" /* webpackChunkName: "component---src-pages-calendar-archives-2024-js" */),
  "component---src-pages-calendar-archives-js": () => import("./../../../src/pages/calendar/archives.js" /* webpackChunkName: "component---src-pages-calendar-archives-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schoolresources-js": () => import("./../../../src/pages/schoolresources.js" /* webpackChunkName: "component---src-pages-schoolresources-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */)
}

