// https://github.com/gatsbyjs/gatsby/discussions/36232
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
const ReactDOM = require("react-dom/client");

exports.onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
